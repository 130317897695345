<template>
  <base-layout-two
    page-title="Employer"
    page-default-back-link="/employers"
    end-button-text="Edit"
    :end-button-url="`/employers/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ employer.name }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Employer Details">
        <base-field-display label-text="Name">
          <p style="white-space: pre-line">{{ employer.name }}</p>
        </base-field-display>

        <base-field-display label-text="Offer">
          <p style="white-space: pre-line">{{ employer.offer }}</p>
        </base-field-display>

        <base-field-display label-text="Contact">
          <p>{{ employer.contact }}</p>
        </base-field-display>

        <base-field-display label-text="URL">
          <p>{{ employer.url }}</p>
        </base-field-display>

        <base-field-display label-text="Short URL">
          <p>{{ employer.url_short }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      employer: {},
    };
  },

  ionViewWillEnter() {
    this.fetchEmployer();
  },

  methods: {
    async fetchEmployer() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/employers/${this.$route.params.id}`)
        .then((response) => {
          this.employer = response.data.employer;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>